
import request from './axios'

// 问题列表
export function mail_send(data) {
  return request({
    url: '/web/mail_send',
    method: 'post',
    data
  })
}