<template>
  <div id="solutionwrp">
    <div class="solution">
      <div class="topview">
        <div class="title">商家中心</div>
        <div class="desc">共创共赢, 寻求留学行业新机遇</div>
        <div class="btnbox">
          <div class="menu" @click="scrollBtm">登录 <i class="iconfont icon-enter enter" /> </div>
          <div class="menu register" @click="scrollBtmregist">注册 <i class="iconfont icon-enter enter" /> </div>
        </div>
      </div>
    </div>
    <div class="stepone">
      <div class="title">留学机构面临的挑战</div>
      <div class="itemwrap">
        <div class="showitem">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/merchant/icon1.png" alt="">
          <p class="desc" >疫情常态化，业务锐减拓客受限</p>
        </div>
        <div class="showitem">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/merchant/icon2.png" alt="">
          <p class="desc" >缺乏互联网技术和运营能力</p>
        </div>
        <div class="showitem">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/merchant/icon3.png" alt="">
          <p class="desc" >深耕行业多年，缺少品牌口碑沉淀</p>
        </div>
      </div>
    </div>
    <div class="steptwo">
      <div class="steptwo_l">
        <div class="title">
          解决方案介绍
        </div>
        <div class="con">
          <span :class="currentIndex == index ? 'active' : 'clickitem'" @click="handleItemClick(index)" v-for="(item,index) of clickdata" :key="index">{{item}}</span>
        </div>
      </div>
      <div class="steptwo_r">
        <img class="img" :src="currentimg" alt="">
      </div>
    </div>
    <div class="stepthree">
      <div class="title">轻松三步即可免费入驻</div>
      <div class="main"></div>
    </div>
    <div class="bottomview">
      <div class="btmcon">
        <span class="touch">合作伙伴</span>
      </div>
      <img src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/merchant/hb.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentIndex: 0,
      currentimg:'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/merchant/plan1.png',
      clickdata: [
        '入驻趣探路留学社区：一键接入阿里等生态，多渠道获客',
        '创建品牌小程序：展示公司业务、顾问老师、商品和成功案例，积累数据打造公司品牌',
        '多渠道品牌曝光，趣探路与各大新媒体平台、权威媒体渠道深度合作，协助机构品牌曝光，更精准地触达用户'
      ]
    }
  },
  methods: {
    handleItemClick(index) {
      this.currentIndex = index
      this.currentimg = index == 0 ? 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/merchant/plan1.png' : 
                        index == 1 ? 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/merchant/plan2.png' : 
                        index == 2 ? 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/merchant/plan3.png' : ''
    },
    scrollBtm () {
      window.open('/admin.html', '_blank')
    },
    scrollBtmregist() {
      window.open('/register.html?pageSource=shop', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.solution{
  height: 663px;
  background: url('https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/merchant/banner.png');
  background-size: cover;
  .topview{
    width: 1220px;
    margin: 0 auto;
    clear: both;
    overflow: hidden;
    .title{
      font-size: 44px;
      font-weight: 600;
      color: #121212;
      margin-top: 240px;
    }
    .desc{
      font-size: 20px;
      font-weight: 500;
      color: #121212;
      margin-top: 18px;
    }
    .btnbox{
      margin-top: 60px;
      display: flex;
      .menu{
        width: 155px;
        height: 62px;
        background: #444444;
        border-radius: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        cursor: pointer;
        .enter{
          font-size: 13px;
          margin-left: 16px;
          color: #fff;
        }
      }
      .register{
        margin-left: 40px;
      }
    }
  }
}
  .stepone{
    width: 1220px;
    margin: 60px auto 160px;
    .title{
      font-size: 36px;
      font-weight: 600;
      color: #121212;
    }
    .itemwrap{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      .showitem{
        width: 320px;
        margin-top: 61px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        .icon{
          // width: 64px;
          height: 64px;
        }
        .desc{
          font-size: 20px;
          font-weight: 600;
          color: #121212;
          margin-top: 21px;
        }
        .con{
          font-size: 14px;
          color: #444444;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
  .steptwo{
    width: 1220px;
    margin: 0 auto 160px;
    display: flex;
    justify-content: space-between;
    .steptwo_l{
      margin-right: 80px;
      flex: 1;
      .title{
        font-size: 36px;
        font-weight: 600;
        color: #121212;
        margin-top: 22px;
        span{
          font-size: 20px;
          font-weight: 500;
          color: #121212;
          padding-left: 10px;
        }
      }
      .con{
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        .clickitem,.active{
          width: 100%;
          font-size: 16px;
          color: #121212;
          padding: 20px;
          background: #fff;
          border-radius: 39px;
          cursor: pointer;
        }
        .active{
          background: #F8F6F6;
        }
      }
    }
    .steptwo_r{
      .img{
        width: 714px;
        height: 463px;
      }
    }
  }
  .stepthree{
    width: 1220px;
    margin: 0 auto 160px;
    .title{
      font-size: 36px;
      font-weight: 600;
      color: #121212;
    }
    .main{
      margin-top: 60px;
      width: 100%;
      height: 180px;
      background-image: url('https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/merchant/three.png');
      background-size: 100%;
    }
  }
  .bottomview{
    position: relative;
    img {
      width: 100%;
    }
    .btmcon{
      position: absolute;
      left: 50%;
      top: 0;
      width: 1220px;
      height: 100%;
      margin-left: -610px;
      display: flex;
      .touch{
        font-size: 36px;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 60px;
      }
    }
  }
</style>
