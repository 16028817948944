import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
import qs from 'qs'
// create an axios instance
const service = axios.create({
  baseURL: '/ask', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
  sendSuscced: true,
  retry: 4,
  retryDelay: 1000
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const options = {
      ...config.data,
      ...config.params
    }
    // if (store.getters.token) {
    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation
    // //   config.headers['Authorization'] = getToken()
    // }
    if (config.method === 'post') {
      if (config.headers['Content-Type'] === 'application/json') {
        config.data = options
      } else if (config.headers['Content-Type'] !== 'multipart/form-data') {
        config.data = options
        config.data = qs.stringify(config.data)
      }
    } else if (config.method === 'get') {
      config.params = options
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (!res.status) {
      return res
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.status !== '200') {
      return res
    } else {
      return res
    }
  },
  error => {
    // if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    //   var newHttp = new Promise(function(resolve) {
    //     resolve()
    //   })
    //   return newHttp.then(function() {
    //     return axios(error.config)
    //   })
    // }
    return error
  }
)

export default service
