<template>
  <div id="solutionwrp">
    <div class="solution">
      <div class="topview">
        <div class="title">创作者计划</div>
        <div class="desc">分享真实的留学经历和生活  共建有温度的留学知识社区</div>
        <div class="menu" @click="scrollBtm">去创作 <i class="iconfont icon-enter enter" /> </div>
      </div>
    </div>
    <div class="stepone">
      <div class="title">创作者们</div>
      <div class="itemwrap">
        <div class="showitem">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/icon1.png" alt="">
          <p class="desc" >热爱分享的留学生</p>
        </div>
        <div class="showitem">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/icon2.png" alt="">
          <p class="desc" >海外生活的华人华侨</p>
        </div>
        <div class="showitem">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/icon3.png" alt="">
          <p class="desc" >海外教育行业从业者</p>
        </div>
      </div>
    </div>
    <div class="steptwo">
      <div class="steptwo_l">
        <div class="title">
          共建真实的留学社区
        </div>
        <div class="con">
          <span :class="currentIndex == index ? 'active' : 'clickitem'" @click="handleItemClick(index)" v-for="(item,index) of clickdata" :key="index">{{item}}</span>
          <img class="clickitem clickitemimg" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/sqsearch.png" alt="">
        </div>
      </div>
      <div class="steptwo_r">
        <img class="img" :src="currentimg" alt="">
      </div>
    </div>
    <div class="bottomview">
      <div class="btmcon">
        <div class="touch">趣探路留学社区创作达人</div>
        <div class="userlist">
          <img class="user" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/user1.png" alt="">
          <img class="user" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/user2.png" alt="">
          <img class="user" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/user3.png" alt="">
          <img class="user" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/user4.png" alt="">
          <img class="user" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/user5.png" alt="">
          <img class="user" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/user6.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentIndex: 0,
      currentimg:'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/plan1.png',
      clickdata: [
        '分享出国留学经验心得',
        '帮助新人答疑解惑',
        '介绍海外风土人情',
        '展示留学生活的点点滴滴'
      ]
    }
  },
  methods: {
    handleItemClick(index) {
      this.currentIndex = index
      this.currentimg = index == 0 ? 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/plan1.png' : 
                        index == 1 ? 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/plan2.png' : 
                        index == 2 ? 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/plan3.png' : 
                        index == 3 ? 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/plan4.png' : '' 
    },
    scrollBtm () {
      let isLogin = window.localStorage.getItem('organAccounts')
      console.log(isLogin)
      if (isLogin) {
        window.open('/space.html?pageSource=user&isLogin='+isLogin, '_blank')
      } else {
        window.open('/home.html?pageSource=user&isLogin='+isLogin, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.solution{
  height: 663px;
  background: url('https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/banner.png');
  background-size: cover;
  .topview{
    width: 1220px;
    margin: 0 auto;
    clear: both;
    overflow: hidden;
    .title{
      font-size: 44px;
      font-weight: 600;
      color: #121212;
      margin-top: 240px;
    }
    .desc{
      font-size: 20px;
      font-weight: 500;
      color: #121212;
      margin-top: 18px;
    }
    .menu{
      margin-top: 60px;
      width: 185px;
      height: 62px;
      background: #444444;
      border-radius: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
      .enter{
        font-size: 13px;
        margin-left: 16px;
        color: #fff;
      }
    }
  }
}
  .stepone{
    width: 1220px;
    margin: 60px auto 160px;
    .title{
      font-size: 36px;
      font-weight: 600;
      color: #121212;
    }
    .itemwrap{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      .showitem{
        width: 320px;
        margin-top: 61px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        .icon{
          // width: 64px;
          height: 64px;
        }
        .desc{
          font-size: 20px;
          font-weight: 600;
          color: #121212;
          margin-top: 21px;
        }
        .con{
          font-size: 14px;
          color: #444444;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
  .steptwo{
    width: 1220px;
    margin: 0 auto 160px;
    display: flex;
    justify-content: space-between;
    .steptwo_l{
      margin-right: 80px;
      flex: 1;
      .title{
        font-size: 36px;
        font-weight: 600;
        color: #121212;
        margin-top: 22px;
        span{
          font-size: 20px;
          font-weight: 500;
          color: #121212;
          padding-left: 10px;
        }
      }
      .con{
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        .clickitem,.active{
          width: 100%;
          font-size: 16px;
          color: #121212;
          padding: 20px;
          background: #fff;
          border-radius: 39px;
          cursor: pointer;
        }
        .active{
          background: #F8F6F6;
        }
        .clickitemimg{
          border-radius: 0;
          width: 436px;
          height: 62px;
          padding: 0;
          margin-top: 20px;
        }
      }
    }
    .steptwo_r{
      .img{
        width: 714px;
        height: 463px;
      }
    }
  }
  .stepthree{
    width: 1220px;
    margin: 0 auto 160px;
    display: flex;
    justify-content: space-between;
    .stepthree_l{
      margin-right: 80px;
      .img{
        width: 680px;
        height: 400px;
      }
    }
    .stepthree_r{
      flex: 1;
      .title{
        font-size: 36px;
        font-weight: 600;
        color: #121212;
        margin-top: 22px;
        span{
          font-size: 20px;
          font-weight: 500;
          color: #121212;
          padding-left: 10px;
        }
      }
      .desc{
        margin-top: 60px;
        font-size: 16px;
        font-weight: 500;
        color: #444444;
      }
      .codewrap{
        display: flex;
        align-items: center;
        margin-top: 62px;
        .codeimg{
          width: 110px;
          height: 110px;
        }
        .tip{
          margin-left: 20px;
          font-size: 16px;
          font-weight: 500;
          color: #121212;
        }
      }
    }
  }
.bottomview{
  height: 580px;
  position: relative;
  background: url('https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/framer/dr.png');
  background-size:cover;
  img {
    width: 100%;
  }
  .btmcon{
    width: 1220px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -610px;
    .touch{
      font-size: 36px;
      font-weight: 500;
      color: #FFFFFF;
      margin-top: 60px;
      width:100%;
      margin-bottom: 40px;
    }
    .userlist{
      display: flex;
      justify-content:space-between;
      flex-wrap: wrap;
      .user{
        width: 240px;
        height: 172px;
        margin-bottom: 36px;
        margin-right: 210px;
        &:nth-child(3) {
          margin-right: 0;
        }
        &:nth-child(6) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
