<template>
  <div class="detail">
    <!-- <div class="title">官宣！趣探路携手支付宝校园生活平台 共创数字留学新社区！</div>
    <div class="info">
      <span class="type">中国财富网官方</span>
      <span class="time">2019-05-27 14:49</span>
    </div> -->
    <div class="con">
      <img :src="img" alt="">
    </div>
  </div>
</template>

<script>
import {newslist} from '../utils/data'
export default {
  data () {
    return {
      img:'',
      newsData:[],
      id: this.$route.query.id
    }
  },
  mounted () {
    newslist.map(item=>{
      if (item.id == this.id) {
        this.img = item.detail
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.detail{
  margin-top: 80px;
}
.con{
  padding-bottom: 160px;
  img{
    width: 100%;
  }
}
  // .detail{
  //   width: 1220px;
  //   margin: 0 auto;
  //   margin-top: 160px;
  //   .title{
  //     font-size: 44px;
  //     font-weight: bold;
  //     color: #121212;
  //   }
  //   .info{
  //     margin-top: 40px;
  //     display: flex;
  //     font-size: 14px;
  //     color: #666;
  //     .type{
  //       padding-right: 20px;
  //       position: relative;
  //       &::after{
  //         content: '';
  //         position: absolute;
  //         right: 0;
  //         bottom: 0;
  //         top: 0;
  //         width: 1px;
  //         background: #E5E5E5;
  //       }
  //     }
  //     .time{
  //       padding-left: 20px;
  //     }
  //   }
  // }
</style>