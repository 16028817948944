<template>
  <div class="about">
    <h1>studentbroad</h1>
  </div>
</template>
<script>
export default {
  beforeEnter: () => {
    location.href = 'http://ask.qutanlu.com'
  }
}
</script>
