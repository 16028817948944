<template>
  <div id="solutionwrp">
    <div class="solution">
      <div class="topview">
        <div class="title">校外教育云平台</div>
        <div class="desc">专为青少年宫等校外教育机构定制的创新教育教学及工作模式</div>
        <div class="menu" @click="scrollBtm">立即咨询 <i class="iconfont icon-enter enter" /> </div>
      </div>
    </div>
    <div class="stepone">
      <div class="title">传统青少年宫面临的挑战</div>
      <div class="itemwrap">
        <div class="showitem">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solucloud/icon1.png" alt="">
          <p class="desc" >校外培训机构冲击，学生流失严重</p>
          <p class="con" >校外培训机构以低价、专业的培训冲击原有的课外培训教育市场，学生参与数参与度逐年下降</p>
        </div>
        <div class="showitem">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solucloud/icon2.png" alt="">
          <p class="desc" >影响力逐年下降</p>
          <p class="con" >运营手段单一，区域内对学生家长人群覆盖能力有限，青少年宫在当地的影响力逐年下降</p>
        </div>
        <div class="showitem">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solucloud/icon3.png" alt="">
          <p class="desc" >数字管理能力缺失</p>
          <p class="con" >决策无依据，日常运营无数据记录与沉淀，学生无电子档案，管理决策拍脑袋，改进效果差</p>
        </div>
      </div>
    </div>
    <div class="steptwo">
      <div class="steptwo_l">
        <div class="title">
          解决方案介绍<span>/数字青少年宫</span>
        </div>
        <div class="con">
          <span :class="currentIndex == index ? 'active' : 'clickitem'" @click="handleItemClick(index)" v-for="(item,index) of clickdata" :key="index">{{item}}</span>
        </div>
      </div>
      <div class="steptwo_r">
        <img class="img" :src="currentimg" alt="">
      </div>
    </div>
    <div class="stepthree">
      <div class="stepthree_l">
        <img class="img" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solucloud/plan.png" alt="">
      </div>
      <div class="stepthree_r">
        <div class="title">
          客户案例<span>/杭州市西湖区青少年宫</span>
        </div>
        <div class="desc">数字化青少年宫云平台，服务全区中小学文艺、书画、图书、科技、共青团、少先队等活动的组织以及校外教育教学培训，下辖120+中小学幼儿园，人群30万+。</div>
        <div class="codewrap">
          <img class="codeimg" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solucloud/code.png" alt="">
          <span class="tip"><span class="green">微信</span>扫一扫查看官方报道</span>
        </div>
      </div>
    </div>
    <div class="bottomview">
      <div class="btmcon">
        <span class="touch">联系我们</span>
        <div class="btntip">或留下您的联系方式，我们将有专人与您联系</div>
        <input class="inp" type="text" :placeholder="usernamePlace" :class="{redColor: usernamePlace != '姓名'}" v-model="username">
        <input class="inp" type="text" :placeholder="phonePlace" :class="{redColor: phonePlace != '电话'}" v-model="phone">
        <div class="apply" @click="handleSubmit">
          提交
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import { mail_send } from '../utils/api'
export default {
  data () {
    return {
      currentIndex: 0,
      currentimg:'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solucloud/example1.png',
      clickdata: [
        '触达区域内所有学生家长',
        '解决信息传达问题，通知一步到位',
        '活动参与更便捷，评比更高效',
        '打造在线课堂，让学生家长更有收获',
        '生成学生电子档案，记录每步成长',
        '数据支撑运营，管理决策'
      ],
      username: '',
      phone: '',
      usernamePlace: '姓名',
      phonePlace: '电话'
    }
  },
  methods: {
    handleItemClick(index) {
      this.currentIndex = index
      this.currentimg = index == 0 ? 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solucloud/example1.png' : 
                        index == 1 ? 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solucloud/example2.png' : 
                        index == 2 ? 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solucloud/example3.png' : 
                        index == 3 ? 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solucloud/example4.png' : 
                        index == 4 ? 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solucloud/example5.png' : 
                        index == 5 ? 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solucloud/example6.png' : ''
    },
    scrollBtm () {
      window.scrollTo(0,2000);
    },
    handleSubmit() {
      if(!this.username) {
        this.usernamePlace = '请填写姓名'
        return
      }
      if (!this.phone) {
        this.phonePlace = '请填写电话'
        return
      }
      mail_send({
        source: '校外教育云平台',
        content: this.username + ';' + this.phone
      }).then(res => {
        if(res.status == '200') {
          this.$message.success('信息已提交成功')
        }
      })
      // axios.post('http://ask.qutanlu.com/app/web/mail_send', {
      //   source: '校外教育云平台',
      //   content: this.username + ';' + this.phone
      // }, {
      //   headers: {
      //     changeOrigin: true,
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Headers": "Content-Type, AUTHENTICATE",
      //     "Content-Type": "application/x-www-form-urlencoded"
      //   }
      // }).then(res => {
      //   if(res.status == '200') {
      //     this.$message.success('信息已提交成功')
      //   }
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.solution{
  height: 663px;
  background: url('https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solucloud/banner.png');
  background-size: cover;
  .topview{
    width: 1220px;
    margin: 0 auto;
    clear: both;
    overflow: hidden;
    .title{
      font-size: 44px;
      font-weight: bold;
      color: #121212;
      margin-top: 240px;
    }
    .desc{
      font-size: 20px;
      font-weight: 500;
      color: #121212;
      margin-top: 18px;
    }
    .menu{
      margin-top: 60px;
      width: 185px;
      height: 62px;
      background: #444444;
      border-radius: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
      .enter{
        font-size: 13px;
        margin-left: 16px;
        color: #fff;
      }
    }
  }
}
  .stepone{
    width: 1220px;
    margin: 60px auto 160px;
    .title{
      font-size: 36px;
      font-weight: bold;
      color: #121212;
    }
    .itemwrap{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      .showitem{
        width: 320px;
        margin-top: 61px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        .icon{
          // width: 64px;
          height: 64px;
        }
        .desc{
          font-size: 20px;
          font-weight: 600;
          color: #121212;
          margin-top: 21px;
        }
        .con{
          font-size: 14px;
          color: #444444;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
  .steptwo{
    width: 1220px;
    margin: 0 auto 160px;
    display: flex;
    justify-content: space-between;
    .steptwo_l{
      margin-right: 80px;
      flex: 1;
      .title{
        font-size: 36px;
        font-weight: bold;
        color: #121212;
        margin-top: 22px;
        span{
          font-size: 20px;
          font-weight: 500;
          color: #121212;
          padding-left: 10px;
        }
      }
      .con{
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        .clickitem,.active{
          width: 100%;
          font-size: 16px;
          color: #121212;
          padding: 20px;
          background: #fff;
          border-radius: 39px;
          cursor: pointer;
        }
        .active{
          background: #F8F6F6;
        }
      }
    }
    .steptwo_r{
      .img{
        width: 714px;
        height: 463px;
      }
    }
  }
  .stepthree{
    width: 1220px;
    margin: 0 auto 160px;
    display: flex;
    justify-content: space-between;
    .stepthree_l{
      margin-right: 80px;
      .img{
        width: 680px;
        height: 400px;
      }
    }
    .stepthree_r{
      flex: 1;
      .title{
        font-size: 36px;
        font-weight: bold;
        color: #121212;
        margin-top: 22px;
        span{
          font-size: 20px;
          font-weight: 500;
          color: #121212;
          padding-left: 10px;
        }
      }
      .desc{
        margin-top: 60px;
        font-size: 16px;
        font-weight: 500;
        color: #444444;
      }
      .codewrap{
        display: flex;
        align-items: center;
        margin-top: 62px;
        .codeimg{
          width: 110px;
          height: 110px;
        }
        .tip{
          margin-left: 20px;
          font-size: 16px;
          font-weight: 500;
          color: #121212;
        }
      }
    }
  }
  .bottomview{
    background-image: url('https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solucloud/touch.png');
    background-size: 100%;
    height: 456px;
    .btmcon{
      width: 1220px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-flow: column;
      align-items: center;
      .touch{
        font-size: 36px;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 60px;
      }
      .btntip{
        margin-top: 10px;
        margin-bottom: 40px;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
      }
      .inp{
        width: 507px;
        height: 54px;
        font-size: 14px;
        color: #999999;
        text-indent: 20px;
        margin-bottom: 12px;
        background: #fff;
        border-radius: 4px; 
      }
      .redColor::-webkit-input-placeholder {
        color: red;
      }
      .redColor::-moz-placeholder{   /* Mozilla Firefox 19+ */
        color:red;
      }
      .redColor:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
        color:red;
      }
      .redColor:-ms-input-placeholder{  /* Internet Explorer 10-11 */ 
        color:red;
      }
      .apply{
        margin-top: 30px;
        width: 148px;
        height: 54px;
        background: #FFFFFF;
        border-radius: 4px;
        text-align: center;
        line-height: 54px;
        font-size: 14px;
        font-weight: 600;
        color: #121212;
        cursor: pointer;
      }
    }
  }
  .green {
    color: #1ac652;
  }
</style>
