<template>
  <div>
    <div class="solution">
      <div class="topview">
        <div class="title">新闻中心</div>
      </div>
    </div>
    <ul class="tabs">
      <li v-for="(item,index) of clickdata" :key="index" :class="currentIndex == index ? 'active' : 'clickitem'" @click="handleClick(index)">{{item}}</li>
    </ul>
    <ul class="news">
      <li class="item" v-for="(item,index) of newsData" :key="index" @click="handleToDetail(item.id)">
        <div class="item_l">
          <img class="newimg" :src="item.img" alt="">
        </div>
        <div class="item_r">
          <p class="title textEllipsis1">{{item.title}}</p>
          <p class="desc textEllipsis2">{{item.desc}}</p>
          <p class="info">
            <span class="type">{{item.type}}</span>
            <span class="time">{{item.time}}</span>
            <span class="yuan" @click.stop="handleClickLink(item.linkUrl)">查看原文</span>
          </p>
        </div>
      </li>
    </ul>
    <!-- <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="prev, pager, next"
        :total="total">
      </el-pagination>
    </div> -->
  </div>
</template>
<script>
import {newslist} from '../utils/data'
export default {
  data () {
    return {
      currentIndex: 0,
      currentPage: 1,
      total: 0,
      clickdata: [
        '媒体报道',
        '公司动态'
      ],
      newsData:[]
    }
  },
  mounted () {
    this.newsData = newslist.filter(item => item.newsType === '1')
    this.total = newslist.length
  },
  methods: {
    handleClick(index) {
      let newArr = []
      this.currentIndex = index
      if (index == 1) {
        newslist.map(item=>{
          if (item.newsType == '2') {
            newArr.push(item)
          }
        })
      } else {
        newArr = newslist.filter(item => item.newsType === '1')
      }
      this.newsData = newArr
    },
    handleClickLink(url) {
      window.open(url,'_blank')
    },
    handleCurrentChange(index) {
      this.currentPage = index
    },
    handlenextpage() {
      this.currentPage += 1
    },
    handleToDetail(id) {
      this.$router.push({path: 'Newsdetail', query: {id: id}});
    }
  }
}
</script>
<style lang="scss" scoped>
.solution{
  height: 663px;
  background: url('https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/news/banner.png');
  background-size: cover;
  .topview{
    width: 1220px;
    margin: 0 auto;
    clear: both;
    overflow: hidden;
    .title{
      font-size: 44px;
      font-weight: 600;
      color: #121212;
      margin-top: 240px;
    }
  }
}
.tabs{
  width: 1220px;
  display: flex;
  margin: 0 auto;
  margin-top: 60px;
  border-bottom: 1px solid #D8D8D8;
  cursor: pointer;
  .clickitem,.active{
    font-size: 18px;
    font-weight: 500;
    color: #121212;
    margin-right: 60px;
    height: 45px;
    // padding-bottom: 12px;
  }
  .active{
    font-size: 24px;
    color: #121212;
    border-bottom: 3px solid #444444;
  }
}
.news{
  width: 1220px;
  margin: 0 auto 160px;
  .item{
    display: flex;
    margin-top: 60px;
    height: 180px;
    overflow: hidden;
    cursor: pointer;
    .item_l{
      width: 270px;
      margin-right: 40px;
      .newimg{
        width: 100%;
      }
    }
    .item_r{
      flex: 1;
      position: relative;
      .title{
        font-size: 18px;
        font-weight: bold;
        color: #121212;
      }
      .desc{
        font-size: 14px;
        color: #444;
        margin-top: 20px;
      }
      .info{
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        font-size: 14px;
        color: #666;
        .type{
          padding-right: 20px;
          position: relative;
          &::after{
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            width: 1px;
            background: #E5E5E5;
          }
        }
        .time{
          padding-left: 20px;
          color: #999;
        }
        .yuan {
          color: #d6b778;
          font-size: 14px;
          margin-left: 60px;
          cursor: pointer;
        }
      }
    }
  }
}
.block{
  display: flex;
  margin-top: 60px;
  margin-bottom: 160px;
  align-items: center;
  justify-content: center;
  .nextpage {
    font-size: 15px;
    color: #121212;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.el-pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  .el-pager{
    display: flex;
    cursor: pointer;
    .number{
      margin-right: 32px;
      font-size: 15px;
      color: #121212;
    }
    .active{
      color: #999999;
    }
  }
}

</style>
