<template>
  <div>
    <div class="solution">
      <div class="topview">
        <div class="title">海外学习提升</div>
        <div class="desc">专注于为在职人群提供海外优质硕/博项目</div>
        <div class="menu" @click="scrollBtm">立即咨询 <i class="iconfont icon-enter enter" /></div>
      </div>
    </div>
    <div class="stepone">
      <div class="title">在职人士面临的挑战</div>
      <div class="itemwrap">
        <div class="showitem">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solution/icon3.png" alt="">
          <p class="desc" >学历不够公司晋升遇瓶颈</p>
        </div>
        <div class="showitem">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solution/icon2.png" alt="">
          <p class="desc" >个人能力跟不上组织发展速度</p>
        </div>
        <div class="showitem">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solution/icon1.png" alt="">
          <p class="desc" >需要拓展人脉没有更好的方式</p>
        </div>
      </div>
    </div>
    <div class="steptwo">
      <div class="steptwo_l">
        <div class="title">
          解决方案介绍<span>/在职人士学习提升</span>
        </div>
        <div class="con">想要通过系统性学习进修个人能力的提升，却苦于没有时间备考国内硕博项目。趣探路为有学习需求的在职人群严选了海外名校的优质硕士、博士学习项目。免联考即可申请海外硕士、博士项目，线上线下的学习方式更加灵活便捷。</div>
      </div>
      <div class="steptwo_r">
        <img class="img" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solution/play.png" alt="">
      </div>
    </div>
    <div class="stepthree">
      <div class="title">解决方案优势</div>
      <div class="itemwrap">
        <div class="showitem">
          <p class="itemtitle">严选项目</p>
          <p class="desc" >严选海外名校硕博项目 中国教育部认可</p>
        </div>
        <div class="showitem">
          <p class="itemtitle">专业团队</p>
          <p class="desc" >海外留学背景专业规划师团队全程贴心服务</p>
        </div>
        <div class="showitem">
          <p class="itemtitle">成功案例</p>
          <p class="desc" >海量成功入学及毕业的真人案例可做参考</p>
        </div>
      </div>
    </div>
    <div class="stepfour">
      <div class="title">学员故事</div>
      <div class="itemwrap">
        <div class="showitem">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solution/story1.png" alt="">
          <p class="desc" >同时兼顾海外进修和子女教育的高校老师</p>
        </div>
        <div class="showitem">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solution/story2.png" alt="">
          <p class="desc" >妈妈的选择，蒙台梭利教育硕士</p>
        </div>
        <div class="showitem">
          <img class="icon" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solution/story3.png" alt="">
          <p class="desc" >让CEO内外兼修的课程</p>
        </div>
      </div>
    </div>
    <div class="bottomview">
      <div class="btmcon">
        <span class="touch">联系我们</span>
        <div class="qrcode">
          <img class="codeimg" src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/home/zaizhicode.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    scrollBtm () {
      window.scrollTo(0,2000);
    }
  }
}
</script>
<style lang="scss" scoped>
.solution{
  height: 663px;
  background: url('https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solution/banner.png');
  background-size: cover;
  .topview{
    width: 1220px;
    margin: 0 auto;
    clear: both;
    overflow: hidden;
    .title{
      font-size: 44px;
      font-weight: 600;
      color: #121212;
      margin-top: 240px;
    }
    .desc{
      font-size: 20px;
      font-weight: 500;
      color: #121212;
      margin-top: 18px;
    }
    .menu{
      margin-top: 60px;
      width: 185px;
      height: 62px;
      background: #444444;
      border-radius: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
      .enter{
        font-size: 13px;
        margin-left: 16px;
        color: #fff;
      }
    }
  }
}
  .stepone{
    width: 1220px;
    margin: 60px auto 160px;
    .title{
      font-size: 36px;
      font-weight: 600;
      color: #121212;
    }
    .itemwrap{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      .showitem{
        width: 320px;
        margin-top: 57px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        .icon{
          width: 74px;
          height: 74px;
        }
        .desc{
          font-size: 20px;
          font-weight: 600;
          color: #121212;
          margin-top: 15px;
        }
      }
    }
  }
  .steptwo{
    width: 1220px;
    margin: 120px auto 160px;
    display: flex;
    justify-content: space-between;
    .steptwo_l{
      margin-right: 110px;
      flex: 1;
      .title{
        font-size: 36px;
        font-weight: 600;
        color: #121212;
        margin-top: 22px;
        span{
          font-size: 20px;
          font-weight: 500;
          color: #121212;
          padding-left: 10px;
        }
      }
      .con{
        font-size: 16px;
        color: #121212;
        line-height: 26px;
        margin-top: 60px;
      }
    }
    .steptwo_r{
      .img{
        width: 714px;
        height: 463px;
      }
    }
  }
  .stepthree{
    width: 1220px;
    margin: 120px auto 160px;
    .title{
      font-size: 36px;
      font-weight: 600;
      color: #121212;
    }
    .itemwrap{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-top: 60px;
      .showitem{
        width: 280px;
        display: flex;
        align-items: center;
        flex-flow: column;
        .itemtitle{
          width: 160px;
          height: 100px;
          background-size: cover;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          font-size: 20px;
          font-weight: 600;
          color: #121212;
        }
        .desc{
          margin-top: 15px;
          font-size: 14px;
          color: #444444;
        }
        &:nth-child(1){
          .itemtitle{
            background: url('https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solution/num1.png');
          }
        }
        &:nth-child(2){
          .itemtitle{
            background: url('https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solution/num2.png');
          }
        }
        &:nth-child(3){
          .itemtitle{
            background: url('https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solution/num3.png');
          }
        }
      }
    }
  }
  .stepfour{
    width: 1220px;
    margin: 120px auto 160px;
    .title{
      font-size: 36px;
      font-weight: 600;
      color: #121212;
    }
    .itemwrap{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-top: 60px;
      .showitem{
        width: 390px;
        display: flex;
        flex-flow: column;
        .icon{
          width: 390px;
          height: 280px;
        }
        .desc{
          margin-top: 20px;
          font-size: 16px;
          color: #121212;
          font-weight: bold;
        }
      }
    }
  }
  .bottomview{
    background: url('https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/solution/touch.png');
    height: 222px;
    background-size: cover;
    .btmcon{
      width: 1220px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .touch{
        font-size: 36px;
        font-weight: 500;
        color: #FFFFFF;
      }
      .qrcode{
        width: 122px;
        height: 122px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 264px;
        position: relative;
        &::after{
          content: '微信扫一扫咨询规划师';
          position: absolute;
          left: -214px;
          width: 224px;
          height: 60px;
          border-radius: 30px 0 0 30px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 600;
          color: #121212;
        }
        .codeimg{
          width: 80px;
          height: 80px;
        }
      }
    }
  }
</style>
