import { Swipe } from 'vant';
<template>
  <div class="about">
    <div class="aboutmetop">
      <div class="a-left">
        <div class="title">关于我们</div>
        <div class="subtitile">数字化专业服务商</div>
        <div class="desc">
          趣探路（杭州）网络技术有限公司是垂直行业的数字化专业服务商，
          提供互联网产品、技术和运营的行业解决方案。
          趣探路团队主要成员来自于阿里系、浙大系和海归系，具有丰富的互联网产品技术和运营经验。
          创始人熊伟曾获得2009年度阿里巴巴总裁特别奖、2015 年国务院办公厅“双创之星”等荣誉，
          受到袁家军书记的表彰。趣探路是钉钉教育生态合作伙伴、支付宝认证服务商等，
          目前服务客户主要有：支付宝、钉钉、浙江大学国际联合商学院、浙江省侨联、西湖区教育局、下城区统战部、
          工商联等政府和行业相关的企事业单位；同时趣探路积极为教育系统赋能，建设第三方国际教育数字化社区和平台，
          服务有国际视野的学生和家长，让教育少走弯路。
        </div>
      </div>
      <div class="a-img">
        <img src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/aboutme.png" alt="">
      </div>
    </div>
    <div class="culture">
      <div class="title">企业文化</div>
      <div class="c-content">
        <div class="c-item" v-for="(item, index) in cultures" :key="index">
          <img class="c-img" :src="item.pic"/>
          <div class="c-font">
            <div class="title">{{item.title}}</div>
            <div class="desc" v-html="item.desc"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="swiper">
      <el-carousel indicator-position="outside" trigger="click" arrow="never" :autoplay="false">
        <el-carousel-item v-for="item in 4" :key="item">
          <h3>{{ item }}</h3>
        </el-carousel-item>
      </el-carousel>
    </div> -->
    <!-- 公司环境 -->
    <div class="environment">
      <div class="title">公司环境</div>
      <div class="e-imgs">
        <div class="e-i-left">
          <img src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/environment1.png" alt="" class="e-img-top">
          <img src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/environment3.png" alt="" class="e-img-bottom">
        </div>
        <div class="e-i-right">
          <img src="https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/environment2.png" alt="" class="e-img-r-top">
        </div>
      </div>
    </div>
    <!-- 领导关怀 -->
    <div class="leadershipcare">
      <div class="title">领导关怀</div>
      <div class="l-box">
        <el-carousel arrow="always" indicator-position="none" :autoplay="false" :loop="false">
          <el-carousel-item v-for="(item, index) in leadershipcares" :key="index">
            <div class="l-itembox">
              <div class="l-item" v-for="(list, idx) in item.arr" :key="idx">
                <img :src="list.pic" :alt="list.desc">
                <div class="desc">{{list.desc}}</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cultures: [
        {
          title: '真实',
          pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/culture4.png',
          desc: '做人真实，做事实在；<br/>有话当面说，对事不对人；<br/>能够虚心接受他人意见并改进'
        },
        {
          title: '勇敢',
          pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/culture2.png',
          desc: '遇到问题敢于面对，积极主动寻找资源解决；<br/>勇于创新，不局限在现有资源和眼界思考和看待问题，<br/>敢于突破敢于尝试'
        },
        {
          title: '信守承诺',
          pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/culture3.png',
          desc: '今日事今日毕，不因个人问题影响客户和团队的结果，不对任何人过度承诺；<br/>执行力坚定，说到做到不打折扣，<br/>值得被信任和依赖'
        },
        {
          title: '成就他人',
          pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/culture1.png',
          desc: '与客户共成长，帮助客户取得好的结果和发展，<br/>与团队共成长，通过自身的努力给与团队积极正向的影响；<br/>平衡短期和长期的利益，积极维护公司的正当权益'
        }
      ],
      leadershipcares: [
        {
          arr: [
            {
              pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/care1.png',
              desc: '浙江省委书记袁家军和熊伟亲切握手，祝贺获得双创之星'
            },
            {
              pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/care2.PNG',
              desc: '民建省委会副主委、杭州市委会主委郭清晔到公司进行考察调研工作'
            },
            {
              pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/care3.png',
              desc: '浙江省政协副主席、中国侨联副主席吴晶等领导莅临公司参观指导'
            }
          ]
        },
        {
          arr: [
            {
              pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/care4.png',
              desc: '杭州市委副书记，萧山区委书记佟桂莉，杭州市副市长陈卫强莅临公司参观指导'
            },
            {
              pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/care5.png',
              desc: '2020年5月15日，下城区政协副主席潘松萍等一行领导莅临趣探路考察走访。'
            },
            {
              pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/care6.png',
              desc: '2020年4月15日，民建省委会副主委，杭州市委会主委郭清晔，省委统战部部位会议成员，干部处处长王高瞻等赴趣探路开展考研调研工作。'
            }
          ]
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.activeClassName {
  color: red;
}
.about {
  width: 1220px;
  margin: 0 auto;
}
.aboutmetop {
  display: flex;
  padding: 120px 0 160px 0;
  .a-left {
    flex: 1;
    margin-right: 83px;
    .title {
      padding: 80px 0 60px 0;
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #121212;
      line-height: 50px;
    }
    .subtitile {
      margin-bottom: 40px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #121212;
      line-height: 28px;
    }
    .desc {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #121212;
      line-height: 22px;
    }
  }
  .a-img, .a-img img {
    width: 593px;
    height: 680px;
  }
} 
.culture {
  .title {
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #121212;
    line-height: 50px;
  }
  .c-content {
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
  }
  .c-item {
    display: flex;
    padding-top: 20px;
    width: 600px;
    &:nth-child(odd) {
      margin-right: 20px;
    }
    .c-img {
      width: 300px;
      height: 240px;
    }
    .c-font {
      padding: 30px;
      flex: 1;
    }
    .title {
      display: inline-block;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #121212;
      line-height: 46px;
      // border-bottom: 3px solid #FAE204;
      margin-bottom: 10px;
      position: relative;
      &:after {
        position: absolute;
        content: '';
        width: 36px;
        height: 3px;
        background-color: #FAE204;
        left: 0;
        bottom: 0;
      }
    }
    .desc {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #444444;
      line-height: 22px;
    }
  }
}
.swipe {
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  
}
/deep/ .el-carousel__indicator--horizontal {
  padding: 12px 4px;
  margin: 0 16px;
}
/deep/ .el-carousel__indicator--horizontal .el-carousel__button {
  width: 160px;
  height: 1px;
  border: 1px solid #f0f0f0;
  background-color: #f0f0f0;
  box-sizing: content-box;
  opacity: 1;
  &:hover {
    border: 1px solid #f0f0f0;
    background-color: #f0f0f0;
    opacity: 1;
  }
}
/deep/ .is-active.el-carousel__indicator--horizontal .el-carousel__button {
  width: 160px;
  height: 1px;
  border: 1px solid #121212;
  background-color: #121212;
  box-sizing: content-box;
  &:hover {
    border: 1px solid #121212;
    background-color: #121212;
    opacity: 1;
  }
}
.environment {
  padding: 160px 0;
  display: flex;
  .title {
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #121212;
    line-height: 50px;
    flex: 1;
  }
  .e-imgs {
    padding: 0 50px;
    width: 850px;
    box-sizing: content-box;
    display: flex;
    .e-i-left {
      padding-right: 20px;
      .e-img-top  {
        float: right;
        width: 350px;
        height: 348px;
        margin-bottom: 20px;
      }
      .e-img-bottom {
        width: 480px;
        height: 292px;
      }
    }
    .e-i-right {
      padding-top: 65px;
      .e-img-r-top {
        width: 350px;
        height: 450px;
      }
    }
  }
}
.leadershipcare {
  padding-bottom: 160px;
  .title {
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #121212;
    line-height: 50px;
  }
  .l-box {
    padding-top: 40px;
    width: 1220px;
    height: 330px;
    box-sizing: content-box;
  }
  .l-itembox {
    width: 1220px;
    height: 330px;
    display: flex;
    justify-content: space-around;
    .l-item {
      width: 390px;
      img {
        width: 390px;
        height: 260px;
      }
      .desc {
        padding-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #121212;
        line-height: 20px;
      }
    }
  }
}
/deep/ .el-carousel__arrow {
  top: 130px;
}
</style>